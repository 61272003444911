import ContactForm from '../../components/ContactForm'
import './index.css'

function Contact() {

  return (
    <div className='contact-wrapper'>
      <div>
        img
      </div>
      <ContactForm/>
    </div>
  )
}

export default Contact